import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import ProviderVouchersForm from '../containers/ProviderVouchersForm'
import RedeemVoucherModalContainer from '../containers/RedeemVoucherModalContainer'
import Heading from '../stories/Heading'
import ClaimVoucherModal from '../containers/ClaimVoucherModal'
import ResponsiveTable from './ResponsiveTable'
import { Toggle } from '../hooks/useToggleOpen'
import ResponsiveVoucherRow from './ResponsiveVoucherRow'
import { useVouchers } from '../context/Vouchers'
import VouchersIndividual from './VouchersIndividual'
import VouchersBundle from './VouchersBundle'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'

const HEADERS = [
  { id: 'info', label: 'Info', align: 'center', type: 'iconOpenClose' },
  { id: 'customer', label: 'Consumer', align: 'left', type: 'memberInfo' },
  { id: 'service', label: 'Service', align: 'left', type: 'text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'datePicker' },
  { id: 'createdAt', label: 'Purchased On', align: 'left', type: 'date' },
  { id: 'code', label: 'Voucher ID', align: 'left', type: 'text' },
  { id: 'status', label: 'Status', align: 'center', type: 'statusChip' },
  { id: 'redeem', label: 'Redeem', align: 'center', type: 'redeemAction' },
]

const BUNDLE_HEADERS = [
  { id: 'service', label: 'Service', align: 'left', type: 'text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'calendar' },
  { id: 'details', label: 'Details', align: 'left', type: 'detail' },
  { id: 'code', label: 'Voucher Id', align: 'left', type: 'text' },
  { id: 'status', label: 'Status', align: 'center', type: 'statusChip' },
  { id: 'redeem', label: 'Redeem', align: 'center', type: 'redeemAction' },
]

const ProviderVouchers = ({ uid }) => {
  const { vouchers, vouchersLoading } = useVouchers()
  // todo: extract loading logic to container
  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12}>
        <Heading variant='body1'>
          Enter order #, voucher # or consumer last name and DOB to search for voucher
        </Heading>
      </Grid>
      <ProviderVouchersForm uid={uid} />
      <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
        <ResponsiveTable style={{ width: '100%', tableLayout: 'fixed' }} headers={HEADERS}>
          <RedeemVoucherModalContainer />
          <ClaimVoucherModal uid={uid} />

          {vouchersLoading ? (
            <TableRow>
              <TableCell align='center' colSpan={HEADERS.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            vouchers.map(voucher => (
              <Toggle key={voucher.uid}>
                {({ open, handleClick, handleClose }) => (
                  <>
                    <ResponsiveVoucherRow
                      key={voucher.uid}
                      headers={HEADERS}
                      voucher={voucher}
                      isActive={open}
                      handleActivate={handleClick}
                      handleDeactivate={handleClose}
                    />
                    {voucher.bundle ? (
                      <VouchersBundle headers={BUNDLE_HEADERS} open={open} voucher={voucher} />
                    ) : (
                      <VouchersIndividual headers={HEADERS} open={open} voucher={voucher} />
                    )}
                  </>
                )}
              </Toggle>
            ))
          )}
        </ResponsiveTable>
      </div>
    </Grid>
  )
}

ProviderVouchers.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default ProviderVouchers
