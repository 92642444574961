import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import GoogleMapReact from 'google-map-react'
import { Box } from '@mui/material'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import LocationMarker from './LocationMarker'
import { useMapEffects } from '../context/MapEffects'

// Depricated
const getDefaultCenter = async () => {
  console.log('getDefaultCenter')
  const VITE_GEOLOCATION = 'https://geolocation-db.com/json/'
  console.log('VITE_GEOLOCATION', VITE_GEOLOCATION)
  const res = await axios
    .get(VITE_GEOLOCATION, { timeout: 5000 })
    .then(response => {
      console.log('response', response)
      return response
    })
    .catch(() => {
      console.log('** Unable to get location **')
      return null
    })

  console.log('res', res)

  if (!res) {
    console.log('** Unable to get location bad response **')
    return null
  }

  const { latitude, longitude } = res?.data
  if (!latitude || !longitude) {
    console.log('** Unable to get location coords **')
    return null
  }

  return {
    lat: latitude,
    lng: longitude,
  }
}

const SimpleMap = ({
  clinics = [],
  displayHeight = '45vh',
  pharmacyPage = false,
  locationSelected = null,
  onPlanOnly = false,
  handleSelectLocation,
  handleSwap,
  activeStep,
}) => {
  const [center, setCenter] = useState(null)
  const [defaultCenter, setDefaultCenter] = useState(null)
  const [markers, setMarkers] = useState([])
  const [allCoords, setAllCoords] = useState([])
  const [zoom, setZoom] = useState(12)
  const [mapAreaCordinates, setMapAreaCordinates] = useState(null)

  const { hoveredData, setSearchMapCordinates } = useMapEffects()

  useEffect(() => {
    if (hoveredData) {
      const cords = hoveredData?._geoloc
      const calculatedCenter = findCenter(cords)
      setCenter(calculatedCenter)
      setZoom(12)
    }
  }, [hoveredData])

  // START - Utility Functions
  const getMiddle = (prop, coords) => {
    let values = coords.map(m => m[prop])
    let min = Math.min(...values)
    let max = Math.max(...values)
    if (prop === 'lng' && max - min > 180) {
      values = values.map(val => (val < max - 180 ? val + 360 : val))
      min = Math.min(...values)
      max = Math.max(...values)
    }
    let result = (min + max) / 2
    if (prop === 'lng' && result > 180) {
      result -= 360
    }
    return result
  }

  const findCenter = coords => {
    console.log('coords', coords)
    console.log('coords.length', coords.length)
    if (coords?.length === 0) {
      return null
    }
    return {
      lat: getMiddle('lat', coords),
      lng: getMiddle('lng', coords),
    }
  }

  const filterOutNullLocations = locs => {
    return locs.filter(location => {
      // eslint-disable-next-line no-underscore-dangle
      if (!location.geopoint && !location._geoloc) {
        return false
      }
      // check if lat or long are 0 or null
      if (
        location.geopoint &&
        // eslint-disable-next-line no-underscore-dangle
        (location.geopoint._lat === 0 || location.geopoint._long === 0)
      ) {
        return false
      }

      if (
        // eslint-disable-next-line no-underscore-dangle
        location._geoloc &&
        // eslint-disable-next-line no-underscore-dangle
        (location._geoloc.lat === 0 || location._geoloc.lng === 0)
      ) {
        return false
      }
      // eslint-disable-next-line no-underscore-dangle
      return true
    })
  }
  // END - Utility Functions

  const buildPharmacyMarkers = pharmacies => {
    const allLocations = []
    const accumulatedCoordinates = []
    pharmacies.map(pharmacy => {
      const { _geoloc, name, error } = pharmacy // eslint-disable-line no-unused-vars

      if (error) {
        return null
      }

      const builtMarkers = _geoloc.map(location => {
        accumulatedCoordinates.push({
          lat: location?.lat || center?.lat,
          lng: location?.lng || center?.lng,
        })
        console.log('USING THIS ID:', pharmacy?.id)
        return (
          <LocationMarker
            data={pharmacy}
            locationID={pharmacy?.id}
            locationName={name.toLowerCase()}
            variant='pharmacyPage'
            address={pharmacy?.address.toLowerCase()}
            city={pharmacy?.city.toLowerCase()}
            state={pharmacy?.state}
            hours={pharmacy?.operatingHours}
            key={pharmacy?.id}
            lat={location?.lat || center?.lat}
            lng={location?.lng || center?.lng}
            handleSelectLocation={handleSelectLocation}
            handleSwap={handleSwap}
            activeStep={activeStep}
          />
        )
      })

      allLocations.push(...builtMarkers)
      return true
    })

    setAllCoords(accumulatedCoordinates)
    return allLocations
  }

  // START - Component Functions
  const buildMarkers = clinicsArr => {
    const allLocations = []
    const accumulatedCoordinates = []
    console.log('clinicsArr', clinicsArr)
    clinicsArr.map((clinic, index) => {
      const { locations, services, error } = clinic // eslint-disable-line no-unused-vars

      console.log('=====================Clinic=====================')
      console.log('clinic', clinic)
      console.log('=====================Locations=====================')
      console.log('locations', locations)

      // if there is an error (from the getClinicLocations hook), do not build markers for this clinic as it may cause issues
      if (error) {
        console.log('error', error)
        return null
      }
      console.log('made it here')

      // Build markers for this speficic clinic and add them to the larger array of all clincis
      // First we filter any locations that have imporper geopoints
      // Then we map over the locations and build the markers UI Components (Nodes)
      const builtMarkers = filterOutNullLocations(locations).map((location, secondIndex) => {
        console.log('=====================Location=====================')
        console.log('location', location)
        // Accumulate all the coords into the allCoords array
        // This is so we can find the center point of the map based on all markers later
        accumulatedCoordinates.push({
          lat:
            // eslint-disable-next-line no-underscore-dangle
            location?.geopoint?._lat || location?._geoloc?.lat || center.lat,
          lng:
            // eslint-disable-next-line no-underscore-dangle
            location?.geopoint?._long || location?._geoloc?.lng || center.lng,
        })

        console.log('=====================Accumulated Coordinates=====================')
        console.log('accumulatedCoordinates', accumulatedCoordinates)
        // Build the marker UI Component (Node) and return it into the array of built markers for this clinic
        console.log('=====================Clinic Location Marker=====================')
        console.log('++++++++++++++++', clinic)
        console.log('location.id', location.id)
        const getLocationName = clinic?.locations?.find(({ id }) => location.id === id)
        console.log('CLINIC: MORE KEY LOGGING:', clinic?.clinicID)
        console.log('location MORE KEY LOGGING:', location?.id)
        console.log('locationUID MORE KEY LOGGING:', location?.uid)
        console.log('locaiotn object id MORE KEY LOGGING:', location?.objectID)

        return (
          <LocationMarker
            data={clinic}
            fromBundle={location.fromBundle}
            locationID={clinic?.clinicID2 || location?.id || location?.uid || location?.objectID}
            variant='clinicPage'
            // This is because we have clinic as a field of the location object
            locationName={
              clinic?.clinic?.name ||
              clinic?.clinicName ||
              getLocationName.name ||
              clinic?.name ||
              ''
            }
            key={clinic?.clinicID || location?.id || location?.uid || location?.objectID}
            lat={
              // eslint-disable-next-line no-underscore-dangle
              location?.geopoint?._lat || location?._geoloc?.lat || center.lat
            }
            lng={
              // eslint-disable-next-line no-underscore-dangle
              location?.geopoint?._long ||
              // eslint-disable-next-line no-underscore-dangle
              location?._geoloc?.lng ||
              center.lng
            }
            text={location?.geopoint ? index + 1 : secondIndex + 1}
            address={location?.address || ''}
            city={location?.city || ''}
            state={location?.state || ''}
            hours={location?.operatingHours || ''}
            services={services || ['']}
            outOfNetwork={clinic?.notOnNetwork || false}
            handleSelectLocation={handleSelectLocation}
            // onPlanOnly={onPlanOnly}
          />
        )
      })

      console.log('builtMarkers', builtMarkers)

      // Add the built markers for this clinic to the allLocations array
      allLocations.push(...builtMarkers)
      return true
    })

    // Set the allCoords state to the accumulatedCoordinates once again this is so we can find the center point of the map based on all markers later
    setAllCoords(accumulatedCoordinates)
    return allLocations
  }
  // END - Component Functions

  // START - Component Lifecycle Functions
  // FIRST STEP - When the clinics are loaded, build the markers
  useEffect(() => {
    console.log('clinics', clinics)
    if (!clinics) {
      return undefined
    }

    if (pharmacyPage) {
      const builtMarkers = buildPharmacyMarkers(clinics)
      setMarkers(builtMarkers)
    } else {
      const builtMarkers = buildMarkers(clinics)
      setMarkers(builtMarkers)
    }
  }, [clinics]) // eslint-disable-line react-hooks/exhaustive-deps

  // SECOND STEP - When the markers are built, find the center of the map, use users location if available, otherwise use the center of all markers
  useEffect(() => {
    console.log('allCoords', allCoords)
    if (!allCoords) {
      console.log('no coords')
      return undefined
    }
    // Center on User
    const getCenter = async () => {
      console.log('getCenter')
      let newDefaultCenter = null
      try {
        newDefaultCenter = await getDefaultCenter()
      } catch (error) {
        console.log('error', error)
        newDefaultCenter = null
      }
      console.log('newDefaultCenter', newDefaultCenter)

      if (!newDefaultCenter) {
        // Center on Markers
        console.log('=====================Center on Markers=====================')
        console.log(allCoords)
        const calculatedCenter = findCenter(allCoords)
        console.log('calculatedCenter', calculatedCenter)
        setDefaultCenter(calculatedCenter)
        return
      }
      console.log('newDefaultCenter', newDefaultCenter)
      setDefaultCenter(newDefaultCenter)
    }
    console.log('calling get center')
    getCenter()
  }, [allCoords]) // eslint-disable-line react-hooks/exhaustive-deps
  // END - Component Lifecycle Functions

  useEffect(() => {
    console.log('center', center)
    console.log('defaultCenter', defaultCenter)
  }, [center, defaultCenter])

  const onBoundsChange = changedCordinated => {
    console.log('changedCordinated----', changedCordinated)
    setMapAreaCordinates(changedCordinated)
  }

  const onMapSearch = () => {
    setSearchMapCordinates(mapAreaCordinates)
  }

  // START - Component Render
  return (
    // Important! Always set the container height explicitly
    <Box>
      <Button
        onClick={onMapSearch}
        variant='outlined'
        size='small'
        fullWidth
        style={{ borderRadius: '0px' }}
      >
        <Typography variant='body1'>Search this area</Typography>
      </Button>
      <Box style={{ height: displayHeight, width: '100%' }}>
        {(center?.lat && center?.lng) || defaultCenter ? (
          <>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBOTTnaTYTcp5rfhvnPQQl_Wv6eVc-7OnQ',
              }}
              center={center || defaultCenter}
              defaultZoom={12}
              zoom={zoom}
              onBoundsChange={onBoundsChange}
            >
              {markers?.length > 0 ? markers.map(marker => marker) : <></>}
            </GoogleMapReact>
          </>
        ) : (
          // center vertically and horizontally
          <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
            {pharmacyPage ? (
              <Typography variant='h4' align='center'>
                {clinics?.length >= 0
                  ? 'Search for a pharmacy.'
                  : 'We are unable to display this map.'}
              </Typography>
            ) : (
              <Typography variant='h4' align='center'>
                {clinics?.length >= 0 ? 'Loading Clinics...' : 'We are unable to display this map.'}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
  // END - Component Render
}

SimpleMap.propTypes = {
  clinics: PropTypes.array,
  displayHeight: PropTypes.string,
  pharmacyPage: PropTypes.bool,
  locationSelected: PropTypes.object,
  onPlanOnly: PropTypes.bool,
}

export default SimpleMap
