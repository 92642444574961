import { memo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '../stories/Typography'
import Divider from '../stories/Divider'
import Button from '../stories/Button'
import AddScriptButton from './AddScriptButton'
import VoucherNewNoteDropdown from './VoucherNewNoteDropdown'
import { useCurrentProfile } from '../context/CurrentUser'
import VoucherChat from './VoucherChat'
import OrderDetailsCard from './OrderDetailsCard'
import OriginalOrderBar from './OriginalOrderBar'
import NotesAttributes from './NotesAttributes'
import GeneralModal from '../containers/GeneralModal'
import Link from '../stories/Link'

import { MuiMarkdown } from 'mui-markdown'
import AddToCalender from './AddToCalender'
import Icon from '../stories/Icons'
import { checkIfDate } from '../libs/validators'
import { formatMarkdown, formatDateForUI } from '@app/libs/formatters'

const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const VoucherDetailsControlBar = ({
  handleClose,
  voucher,
  notes = [],
  orders = [],
  originalOrder = null,
}) => {
  const voucherDetailsRef = useRef(null)
  const { isProvider, email, uid } = useCurrentProfile()
  const profile = useCurrentProfile()
  const [addToCalenderOpen, setAddToCalenderOpen] = useState(false)

  const onClickAddToCalender = () => {
    setAddToCalenderOpen(!addToCalenderOpen)
  }

  return (
    <Box>
      <Box
        sx={{
          justifyContent: {
            xs: 'center',
            sm: 'center',
          },
          marginBottom: {
            xs: '20px',
            sm: '20px',
          },
        }}
      >
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12} md={6} id='voucherDetails'>
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'flex',
                },
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                  lg: 'flex-start',
                },
              }}
              ref={voucherDetailsRef}
            >
              <Typography variant='h3'>Voucher Details</Typography>
            </Box>
          </Grid>
          {isProvider && (
            <>
              <Grid item xs={12} sm={12} md={2}>
                <VoucherNewNoteDropdown />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <AddScriptButton />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={2}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleClose}
              style={{
                height: '40px',
                width: '100%',
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container spacing={2} mt={1} mb={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant='h4' mb={2}>
            Patient Info
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            padding='0px 8px'
          >
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Name:</b> {voucher?.customer?.firstname || '---'}{' '}
              {voucher?.customer?.lastname || '---'}
            </Typography>
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Address:</b> {voucher?.customer?.address?.street || '---'}{' '}
              {voucher?.customer?.address?.city || '---'}{' '}
              {voucher?.customer?.address?.state || '---'}{' '}
              {voucher?.customer?.address?.zip || '---'}
            </Typography>
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Birthday: </b>
              {formatDateForUI(voucher?.customer?.dob || voucher?.customer?.birthday) || '---'}
            </Typography>
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Gender:</b> {voucher?.customer?.gender || '---'}
            </Typography>
            {/* <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize'
              }}
            >
              <b>Date of Birth:</b> {voucher?.customer?.dob || '---'}
            </Typography> */}
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Phone:</b> {voucher?.customer?.phone || '---'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant='h4' mb={2}>
            Voucher Info
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            padding='0px 8px'
          >
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Voucher ID:</b> {voucher.code}
            </Typography>
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Service:</b> {voucher.service.name}
            </Typography>
            <Typography
              variant='body1'
              style={{
                textTransform: 'capitalize',
              }}
            >
              <b>Appointment Date:</b>{' '}
              {voucher?.appointmentDate && typeof voucher.appointmentDate === 'object'
                ? voucher.appointmentDate.toDate().toLocaleString('en-US', dateFormatOptions)
                : 'Appointment Pending'}
            </Typography>
            {checkIfDate(voucher?.appointmentDate) && (
              <Box marginTop='4px'>
                <AddToCalender
                  calenderEvent={{
                    title: voucher?.service?.name || '',
                    description: `Voucher Link:  ${window.location.origin}/dashboard?vc=${voucher.code}`,
                    start: voucher?.appointmentDate?.toDate()?.toISOString(),
                    appointmentDate: voucher?.appointmentDate,
                    duration: [1, 'hour'],
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant='h4' mb={2}>
            Voucher Functions
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            padding='0px 8px'
            gap='12px'
          >
            <Link
              to={`/videochat?id=${voucher.uid}`}
              type='internal'
              display='inline'
              variant='unstyled'
              size='small'
              target='_blank'
              style={{
                padding: '6px 10px',
                borderRadius: '4px',
                backgroundColor: '#652d92',
                color: '#fff',
                boxShadow:
                  '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
              }}
            >
              Join Mishe Video Call
            </Link>
            {isProvider && voucher?.customer?.idLink && (
              <Box marginTop='4px'>
                {/* Open modal to view patient ID */}
                <GeneralModal
                  id='patientID'
                  headerText='Patient Identification'
                  maxWidth={'lg'}
                  customButton={
                    <Button variant='outlined' color='primary' size='small'>
                      <Box display='flex' gap='8px' alignItems='center'>
                        <Icon name='pageViewOutlined' />
                        <Typography variant='body2' family='poppins'>
                          View Patient ID
                        </Typography>
                      </Box>
                    </Button>
                  }
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    // contain imaage within a box
                    style={{ maxWidth: '400px' }}
                  >
                    <img
                      // make sure image fits in box
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      src={voucher?.customer?.idLink}
                      alt='PatientIdentification'
                    />
                  </Box>
                </GeneralModal>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {originalOrder && <OriginalOrderBar order={originalOrder} />}
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Box mt={2}>
            <Typography
              variant='h4'
              mb={2}
              style={{
                textTransform: 'capitalize',
              }}
            >
              Chat
            </Typography>
          </Box>
          <VoucherChat
            chatID={voucher?.id || voucher?.uid}
            clinic={voucher?.organization?.name}
            patientName={voucher?.customer?.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box mt={2}>
            <Typography variant='h4' mb={2}>
              Visit Notes
            </Typography>
          </Box>
          <Divider />
          <Box
            mt={2}
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
            }}
          >
            {/* Begin Notes Generator */}
            <Grid container>
              {notes.map(note => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  key={note.uid}
                  style={{
                    marginBottom: '16px',
                  }}
                >
                  {note.noteType === 'text' && (
                    <Box
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '4px',
                        padding: '20px',
                        height: '100%',
                      }}
                    >
                      <Box>
                        <MuiMarkdown>{formatMarkdown(note?.content || '')}</MuiMarkdown>
                      </Box>
                      <Divider {...{ style: { margin: '1rem 0' } }} />
                      <NotesAttributes
                        {...note}
                        originalOrderId={voucher.emrOrderID || voucher.orderId}
                      />
                    </Box>
                  )}
                  {note.noteType === 'image' && (
                    <Box
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '4px',
                        padding: '20px',
                        height: '100%',
                      }}
                    >
                      <Box key={note.uid} maxWidth='100%' mb={1}>
                        <img
                          src={note?.content || ''}
                          alt='note'
                          width='100%'
                          style={{
                            borderRadius: '5px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography variant='body1' mb={2}>
                          {note.fileName || 'Image'}
                          <a
                            href={note?.content || ''}
                            download={note.fileName}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{
                              marginLeft: '10px',
                              textDecoration: 'none',
                            }}
                          >
                            <Button variant='outlined' size='small' color='primary'>
                              Download
                            </Button>
                          </a>
                        </Typography>
                      </Box>
                      <Divider {...{ style: { margin: '1rem 0' } }} />
                      <NotesAttributes
                        {...note}
                        originalOrderId={voucher.emrOrderID || voucher.orderId}
                      />
                    </Box>
                  )}
                  {note.noteType === 'file' && (
                    <Box
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        borderRadius: '4px',
                        padding: '20px',
                        height: '100%',
                      }}
                    >
                      <Box>
                        <Typography variant='body1' mb={2}>
                          {note.fileName || 'File'}
                          <a
                            href={note?.content || ''}
                            download={note.fileName}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{
                              marginLeft: '10px',
                              textDecoration: 'none',
                            }}
                          >
                            <Button variant='outlined' size='small' color='primary'>
                              Download
                            </Button>
                          </a>
                        </Typography>
                      </Box>
                      <Divider {...{ style: { margin: '1rem 0' } }} />
                      <NotesAttributes
                        {...note}
                        originalOrderId={voucher.emrOrderID || voucher.orderId}
                      />
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
            {/* End of notes generator */}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Box mt={2}>
            <Typography variant='h4' mb={2}>
              Orders
            </Typography>
          </Box>
          <Divider />
          <Box
            mt={2}
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
            }}
          >
            <Grid container>{orders?.map(order => OrderDetailsCard({ order }))}</Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

VoucherDetailsControlBar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      noteType: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      details: PropTypes.shape({
        medicationName: PropTypes.string,
        strength: PropTypes.string,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        refills: PropTypes.shape({
          allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          filled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      }).isRequired,
      trackingInfo: PropTypes.shape({
        trackingNumber: PropTypes.string.isRequired,
        carrier: PropTypes.string.isRequired,
      }),
      timestamp: PropTypes.instanceOf(firebase.firestore.Timestamp).isRequired,
    }),
  ),
  voucher: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
    }).isRequired,
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    emrOrderID: PropTypes.string,
    orderId: PropTypes.string,
  }).isRequired,
  originalOrder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    details: PropTypes.shape({
      medicationName: PropTypes.string,
      strength: PropTypes.string,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      refills: PropTypes.shape({
        allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        filled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    trackingInfo: PropTypes.shape({
      trackingNumber: PropTypes.string.isRequired,
      carrier: PropTypes.string.isRequired,
    }),
    timestamp: PropTypes.instanceOf(firebase.firestore.Timestamp),
  }),
}

export default memo(VoucherDetailsControlBar)
