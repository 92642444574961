import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Heading from '../stories/Heading'
import DashboardTabs from '../components/DashboardTabs'
// import VerifyEMailNotification from '../components/VerifyEMailNotification'
import AcceptTermsNotification from '../containers/AcceptTermsNotification'
import { useCurrentProfile } from '../context/CurrentUser'
import VouchersProvider from '../context/Vouchers'
import VoucherModalProvider from '../context/VoucherModal'
import VoucherDetailsProvider from '../context/VoucherDetails'
import AddNoteModalProvider from '../context/AddNoteModal'
import AddOrderModalProvider from '../context/AddOrderModal'
import Loader from '../stories/Loader'

const DashboardPage = () => {
  const { uid, isProvider, isPlanAdmin, organizationName, firstname, acceptedTerms } =
    useCurrentProfile()

  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Dashboard',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box padding='32px'>
      {!uid && <Loader />}
      {uid && (
        <>
          {/* {!user.emailVerified && !isProvider && <VerifyEMailNotification />} */}
          {!acceptedTerms && isProvider && <AcceptTermsNotification />}
          <Box mb={6}>
            {firstname && (
              <Heading gutterBottom>
                {firstname?.split(-1) === 's' ? (
                  <Box
                    component='span'
                    color='primary.main'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {firstname} &apos;
                  </Box>
                ) : (
                  <Box
                    component='span'
                    color='primary.main'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {firstname}&apos;s
                  </Box>
                )}{' '}
                <i>my mishe</i> dashboard
              </Heading>
            )}
            {isProvider && (
              <Heading variant='h4' weight='medium' component='p' gutterBottom>
                Clinician at {organizationName}
              </Heading>
            )}
            {isPlanAdmin && (
              <Heading variant='h4' weight='medium' component='p' gutterBottom>
                Admin at {organizationName || 'MCC Health Plan'}
              </Heading>
            )}
          </Box>
          <VouchersProvider>
            <VoucherDetailsProvider>
              <VoucherModalProvider>
                <AddNoteModalProvider>
                  <AddOrderModalProvider>
                    <DashboardTabs />
                  </AddOrderModalProvider>
                </AddNoteModalProvider>
              </VoucherModalProvider>
            </VoucherDetailsProvider>
          </VouchersProvider>
        </>
      )}
    </Box>
  )
}

export default DashboardPage
