import PropTypes from 'prop-types'
import VoucherUpcomingAppointment from './VoucherUpcomingAppointment'
import ResponsiveVoucherRow from './ResponsiveVoucherRow'
import { Toggle } from '../hooks/useToggleOpen'
import VouchersBundle from './VouchersBundle'
import VouchersIndividual from './VouchersIndividual'
import ResponsiveTable from './ResponsiveTable'

const HEADERS = [
  { id: 'info', label: 'Info', align: 'center', type: 'iconOpenClose', user: 'consumer' },
  { id: 'organization', label: 'Clinic', align: 'left', type: 'link' },
  { id: 'service', label: 'Service', align: 'left', type: 'text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'date' },
  { id: 'status', label: 'Status', align: 'center', type: 'statusChip' },
]

const HEADERS_BUNDLE = [
  { id: 'info', label: 'Info', align: 'center', type: 'detail' },
  { id: 'organization', label: 'Clinic', align: 'left', type: 'link' },
  { id: 'service', label: 'Service', align: 'left', type: 'text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'date' },
  { id: 'status', label: 'Status', align: 'center', type: 'statusChip' },
]

const PatientVouchers = ({ rows = [] }) => (
  <>
    <VoucherUpcomingAppointment rows={rows} />
    <ResponsiveTable headers={HEADERS}>
      {rows.map(voucher => (
        <Toggle key={voucher.uid}>
          {({ open, handleClick, handleClose }) => (
            <>
              <ResponsiveVoucherRow
                headers={HEADERS}
                voucher={voucher}
                isActive={open}
                handleActivate={handleClick}
                handleDeactivate={handleClose}
              />
              {voucher.bundle ? (
                <VouchersBundle headers={HEADERS_BUNDLE} open={open} voucher={voucher} />
              ) : (
                <VouchersIndividual headers={HEADERS} open={open} voucher={voucher} />
              )}
            </>
          )}
        </Toggle>
      ))}
    </ResponsiveTable>
  </>
)

PatientVouchers.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      code: PropTypes.string,
      service: PropTypes.shape({
        name: PropTypes.string,
      }),
      organization: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
}

export default PatientVouchers
