import { useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const ClaimsActivity = () => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'cpt', headerName: 'CPT Codes', width: 150 },
    {
      field: 'treatment',
      headerName: 'Treatment',
      width: 150,
      editable: true,
    },
    {
      field: 'billed',
      headerName: 'Billed',
      width: 150,
      editable: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      editable: true,
    },
    {
      field: 'clinicName',
      headerName: 'Clinic Name',
      width: 160,
      editable: true,
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      width: 160,
      editable: true,
    },
  ]

  const rows = [
    {
      id: 1,
      cpt: 99213,
      treatment: 'office visit',
      billed: 120,
      date: '10/18/24',
      clinicName: 'Sunshine Medical Center',
      providerName: 'Dr. Emily Johnson',
    },
    {
      id: 2,
      cpt: 97110,
      treatment: 'physical therapy',
      billed: 90,
      date: '10/18/24',
      clinicName: 'Active Life Physical Therapy',
      providerName: 'Dr. Michael Chen',
    },
    {
      id: 3,
      cpt: 70450,
      treatment: 'CT scan head',
      billed: 500,
      date: '10/18/24',
      clinicName: 'City Imaging Center',
      providerName: 'Dr. Sarah Thompson',
    },
    {
      id: 4,
      cpt: 36415,
      treatment: 'blood draw',
      billed: 25,
      date: '10/18/24',
      clinicName: 'QuickLab Diagnostics',
      providerName: 'Nurse Practitioner Alex Rodriguez',
    },
    {
      id: 5,
      cpt: 90837,
      treatment: 'psychotherapy',
      billed: 200,
      date: '10/18/24',
      clinicName: 'Mindful Wellness Center',
      providerName: 'Dr. Rachel Green',
    },
    {
      id: 6,
      cpt: 99285,
      treatment: 'emergency room visit',
      billed: 750,
      date: '10/18/24',
      clinicName: 'Central Hospital ER',
      providerName: 'Dr. James Wilson',
    },
    {
      id: 7,
      cpt: 43235,
      treatment: 'endoscopy',
      billed: 600,
      date: '10/18/24',
      clinicName: 'Gastro Specialists Clinic',
      providerName: 'Dr. Lisa Nguyen',
    },
    {
      id: 8,
      cpt: 81001,
      treatment: 'urinalysis',
      billed: 30,
      date: '10/18/24',
      clinicName: 'UroCare Medical',
      providerName: 'Dr. Robert Brown',
    },
    {
      id: 9,
      cpt: 90471,
      treatment: 'vaccination',
      billed: 60,
      date: '10/18/24',
      clinicName: 'Community Health Clinic',
      providerName: 'Nurse Jessica Lee',
    },
    {
      id: 10,
      cpt: 92014,
      treatment: 'eye exam',
      billed: 180,
      date: '10/18/24',
      clinicName: 'Clear Vision Optometry',
      providerName: 'Dr. David Kim',
    },
    {
      id: 11,
      cpt: 77067,
      treatment: 'mammogram',
      billed: 300,
      date: '10/18/24',
      clinicName: `Women's Health Center`,
      providerName: 'Dr. Maria Garcia',
    },
    {
      id: 12,
      cpt: 99396,
      treatment: 'annual physical',
      billed: 225,
      date: '10/18/24',
      clinicName: 'Family Care Physicians',
      providerName: 'Dr. Thomas Anderson',
    },
    {
      id: 13,
      cpt: 29881,
      treatment: 'knee arthroscopy',
      billed: 1800,
      date: '10/18/24',
      clinicName: 'Orthopedic Specialists',
      providerName: 'Dr. Jennifer Patel',
    },
    {
      id: 14,
      cpt: 71046,
      treatment: 'chest x-ray',
      billed: 120,
      date: '10/18/24',
      clinicName: 'Radiology Associates',
      providerName: 'Dr. Mark Williams',
    },
    {
      id: 15,
      cpt: 97140,
      treatment: 'manual therapy',
      billed: 105,
      date: '10/18/24',
      clinicName: 'Healing Hands Therapy',
      providerName: 'Dr. Samantha Lee',
    },
    {
      id: 16,
      cpt: 99203,
      treatment: 'new patient visit',
      billed: 165,
      date: '10/18/24',
      clinicName: 'Greenwood Medical Group',
      providerName: 'Dr. Christopher Taylor',
    },
    {
      id: 17,
      cpt: 90853,
      treatment: 'group therapy',
      billed: 90,
      date: '10/18/24',
      clinicName: 'Serenity Counseling Center',
      providerName: 'Dr. Amanda Foster',
    },
    {
      id: 18,
      cpt: 11042,
      treatment: 'wound debridement',
      billed: 375,
      date: '10/18/24',
      clinicName: 'Advanced Wound Care Clinic',
      providerName: 'Dr. Daniel Martinez',
    },
    {
      id: 19,
      cpt: 93000,
      treatment: 'ECG',
      billed: 75,
      date: '10/18/24',
      clinicName: 'Heart Health Institute',
      providerName: 'Dr. Elizabeth Chen',
    },
    {
      id: 20,
      cpt: 20610,
      treatment: 'joint injection',
      billed: 150,
      date: '10/18/24',
      clinicName: 'Joint & Spine Center',
      providerName: 'Dr. Andrew Kim',
    },
  ]

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          minHeight: '200px',
        }}
      />
    </Box>
  )
}

export default ClaimsActivity
