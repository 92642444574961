import PropTypes from 'prop-types'
import { TableCell, TableRow, Button, Typography } from '@mui/material'
import IconButton from '../stories/IconButton'
import Icon from '../stories/Icons'
import RedeemVoucherButton from '../containers/RedeemVoucherButton'
import VoucherChip from './VoucherChip'
import WarningText from '../stories/WarningText'
import { formatDateForUI } from '../libs/formatters'
import AddToCalender from './AddToCalender'
import VoucherDetailsEntry from '../containers/voucherDetailsEntry'
import Link from '../stories/Link'
import { checkIfDate } from '../libs/validators'
import DateTimePickerCell from './DateTimePickerCell'

const tableItemsMap = {
  iconOpenClose: ({ isActive, ctaActions }) => (
    <IconButton
      aria-label={isActive ? 'close row' : 'expand row'}
      size='small'
      onClick={isActive ? ctaActions.deactivate : ctaActions.activate}
    >
      <Icon name={isActive ? 'closeArrow' : 'openArrow'} />
    </IconButton>
  ),
  memberInfo: ({ voucher }) => (
    <>
      <Typography variant='body2'>
        {[voucher.customer?.firstname, voucher.customer?.lastname].join(' ').trim() || '---'}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {voucher.customer?.email || '---'}
      </Typography>
    </>
  ),
  text: ({ id, voucher }) => voucher[id]?.name || voucher[id],
  datePicker: ({ voucher }) => (
    <DateTimePickerCell appointmentDate={voucher.appointmentDate} voucherId={voucher.id} />
  ),
  detail: ({ voucher }) => (
    <VoucherDetailsEntry voucher={voucher}>
      <Button size='small' variant='outlined'>
        <Typography variant='body1' family='poppins'>
          Details
        </Typography>
      </Button>
    </VoucherDetailsEntry>
  ),
  statusChip: ({ voucher }) => {
    return <VoucherChip voucher={voucher} />
  },
  link: ({ id, voucher }) =>
    voucher[id] ? (
      <Link
        to={voucher[id]?.slug ? `/clinics/${voucher[id]?.slug}` : ''}
        display='inline'
        variant='unstyled'
        size='small'
      >
        {voucher[id]?.name}
      </Link>
    ) : (
      false
    ),
  calendar: ({ voucher }) =>
    checkIfDate(voucher.appointmentDate) && (
      <AddToCalender
        calenderEvent={{
          title: voucher.service?.name,
          description: `Voucher Link: ${window.location.origin}/dashboard?vc=${voucher.code}`,
          start: voucher.appointmentDate?.toDate()?.toISOString(),
          appointmentDate: voucher?.appointmentDate,
          duration: [1, 'hour'],
        }}
      />
    ),
  date: ({ id, voucher }) => formatDateForUI(voucher[id], true) || '---',
  redeemAction: ({ voucher, parentVoucher }) => {
    if (!voucher.bundle)
      return (
        <>
          {voucher.status === 'purchased' && <RedeemVoucherButton voucher={voucher} />}
          {voucher.status === 'cancelled' && <WarningText type='error'>-dd--</WarningText>}
          {voucher.status === 'redeemed' && <WarningText type='success'>-ss--</WarningText>}
        </>
      )
    if (voucher.bundle && parentVoucher) {
      return <RedeemVoucherButton voucher={parentVoucher} subVoucher={voucher} />
    }
  },
}

/**
 * ResponsiveVoucherRow Component
 * This component renders a table's voucher specific rows, DYNAMICALLY, based on header values formatted a certain way (see below)
 * Make sure you follow the API correctly when using this
 *
 * Its built to be responsive, displaying data in a table format on larger screens
 * and switching to a cards display vertically stacked  smaller mobile screens
 *
 * @param {Array} headers - An array of objects defining the structure and type of each table column.
 * @param {Object} voucher - The data object for the current row, containing the values for each header field.
 * @param {Object} parentVoucher - The parent voucher its a part (bundle parent) if its a subvoucher
 * @param {Boolean} isActive - field denoting if a certain behavior is active. (such as isOpen)
 * @param {Function} handleActivate - function to call when we activate the user interaction behavior (such as click to open)
 * @param {Function} handleDeactivate - function to call when we deactivate the user interaction behavior (such as click to open)
 * @param {String} backgroundColor - optionla coloring to applied to the rows
 *
 * @example
 * // Example usage:
 * const headers = [
 *   { id: 'info', label: 'Info', type: 'iconOpenClose', align: 'center' },
 *   { id: 'organization', label: 'Clinic', type: 'link', align: 'left' },
 *   { id: 'service', label: 'Service', type: 'text', align: 'left' },
 *   { id: 'appDate', label: 'Appt Date', type: 'date', align: 'left' },
 *   { id: 'status', label: 'Status', type: 'chip', align: 'center' }
 * ];
 *
 */

const ResponsiveVoucherRow = ({
  headers,
  voucher,
  parentVoucher,
  isActive,
  handleActivate,
  handleDeactivate,
  backgroundColor = '#FFFFFF',
}) => {
  const renderTableItem = (type, id) => {
    const renderer = tableItemsMap[type]
    if (renderer) {
      return renderer({
        id,
        voucher,
        parentVoucher,
        isActive,
        ctaActions: { activate: handleActivate, deactivate: handleDeactivate },
      })
    }
    return voucher[id] || '---'
  }

  return (
    <TableRow style={{ backgroundColor }} className='responsive-row'>
      {headers.map(({ id, label, type, align }) => (
        <TableCell key={id} data-label={label} align={align}>
          {renderTableItem(type, id)}
        </TableCell>
      ))}
    </TableRow>
  )
}

ResponsiveVoucherRow.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.keys(tableItemsMap)).isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    }),
  ).isRequired,
  voucher: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  handleActivate: PropTypes.func,
  handleDeactivate: PropTypes.func,
  backgroundColor: PropTypes.string,
}

export default ResponsiveVoucherRow
