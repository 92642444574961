import { useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const ProviderDirectory = () => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'clinicName', headerName: 'Clinic Name', width: 200 },
    { field: 'specialty', headerName: 'Specialty', width: 200 },
  ]

  const rows = [
    {
      id: 1,
      name: 'Dr. Emily Johnson',
      clinicName: 'Sunshine Medical Center',
      specialty: 'Family Medicine',
    },
    {
      id: 2,
      name: 'Dr. Michael Chen',
      clinicName: 'Active Life Physical Therapy',
      specialty: 'Physical Therapy',
    },
    {
      id: 3,
      name: 'Dr. Sarah Thompson',
      clinicName: 'City Imaging Center',
      specialty: 'Radiology',
    },
    {
      id: 4,
      name: 'Nurse Practitioner Alex Rodriguez',
      clinicName: 'QuickLab Diagnostics',
      specialty: 'Laboratory Medicine',
    },
    {
      id: 5,
      name: 'Dr. Rachel Green',
      clinicName: 'Mindful Wellness Center',
      specialty: 'Psychiatry',
    },
    {
      id: 6,
      name: 'Dr. James Wilson',
      clinicName: 'Central Hospital ER',
      specialty: 'Emergency Medicine',
    },
    {
      id: 7,
      name: 'Dr. Lisa Nguyen',
      clinicName: 'Gastro Specialists Clinic',
      specialty: 'Gastroenterology',
    },
    {
      id: 8,
      name: 'Dr. Robert Brown',
      clinicName: 'UroCare Medical',
      specialty: 'Urology',
    },
    {
      id: 9,
      name: 'Nurse Jessica Lee',
      clinicName: 'Community Health Clinic',
      specialty: 'Public Health',
    },
    {
      id: 10,
      name: 'Dr. David Kim',
      clinicName: 'Clear Vision Optometry',
      specialty: 'Optometry',
    },
  ]

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          minHeight: '200px',
        }}
      />
    </Box>
  )
}

export default ProviderDirectory
