import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import ResponsiveTable from './ResponsiveTable'
import ResponsiveVoucherRow from './ResponsiveVoucherRow'
import OrderChatView from './OrderChatView'
import { getSubVouchers } from '../utils/voucherUtils'

const VouchersBundle = ({ headers, open = false, voucher = {} }) => {
  const [subVouchers, setSubVouchers] = useState([])

  useEffect(() => {
    const fetchSubVouchers = async () => {
      if (voucher?.bundle && voucher?.subVouchers?.length) {
        setSubVouchers(voucher?.subVouchers)
      } else if (voucher?.bundle) {
        const subVouchersData = await getSubVouchers(voucher.uid, voucher.user.id)
        setSubVouchers(subVouchersData)
      }
    }
    fetchSubVouchers()
  }, [voucher])

  return (
    <TableRow style={{ border: 0, padding: 0 }}>
      <TableCell colSpan={8} style={{ padding: 0 }}>
        <Collapse style={{ width: '100%' }} in={open} timeout='auto' unmountOnExit>
          <Box mb={2}>
            <ResponsiveTable headerColor='#f6eeff' headers={headers}>
              {subVouchers.map(voucher => (
                <ResponsiveVoucherRow
                  backgroundColor='#faf5ff'
                  key={voucher.uid}
                  headers={headers}
                  voucher={voucher}
                  parentVoucher={voucher}
                />
              ))}
              <TableRow style={{ backgroundColor: '#faf5ff' }}>
                <TableCell style={{ padding: '16px' }} colSpan={headers.length}>
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <OrderChatView
                      order={voucher}
                      chatID={voucher?.id || voucher?.uid}
                      color='#652d92'
                      variant='bundle'
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </ResponsiveTable>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default VouchersBundle
