import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'

const PlanDesign = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      width='100%'
      marginTop='64px'
      gap='96px'
    >
      {/* PPO Plan Design */}
      <Box display='flex' flexDirection='column' justifyContent='center' width='100%'>
        <Box
          bgcolor='#652d92'
          display='flex'
          justifyContent='center'
          alignItems='center'
          padding='8px'
        >
          <Typography variant='h1' color='white' margin='0'>
            PPO Plan
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          {/* Left Column */}
          <Box display='flex' flexDirection='column' alignItems='center' width='50%'>
            <Box
              bgcolor='#f1e7fb'
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1'>
                <b>Benefits</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1' mb={2}>
                <b>Deductible: </b>Individual / Family
              </Typography>
              <Typography variant='body1' mb={2}>
                <b>Coinsurance </b>
              </Typography>
              <Typography variant='body1'>
                <b>Out-of-Pocket Max: </b>Individual / Family
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              height='65px'
            >
              <Typography variant='body1'>
                <b>Preventative Care</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1'>
                <b>Virtual Visit</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1'>
                <b>Office / Specialist Visit</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1'>
                <b>Urgent Care</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1'>
                <b>Emergency Room</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='body1'>
                <b>Inpatient Hospital Services</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
            >
              <Typography variant='body1'>
                <b>Outpatient Surgeries / Therapies</b>
              </Typography>
            </Box>
          </Box>
          {/* Right Column */}
          <Box display='flex' flexDirection='column' alignItems='center' width='50%'>
            <Box
              bgcolor='#f1e7fb'
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>
                <b>In-Network</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1' mb={2}>
                $500 / $1,000
              </Typography>
              <Typography variant='body1' mb={2}>
                90%
              </Typography>
              <Typography variant='body1'>$3,500 / $7,000</Typography>
            </Box>
            <Box
              display='flex'
              flexDirection={'column'}
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>100% of Allowable Amount</Typography>
              <Typography variant='body1'>
                <b>Deductible Waived</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>$5 Copay</Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>$15 Copay</Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>$50 Copay</Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>$100 Copay + Deductible + 10%</Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='body1'>Deductible + 10%</Typography>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderLeft='none'
            >
              <Typography variant='body1'>Deductible + 10%</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Prescription Resources Design */}
      <Box display='flex' flexDirection='column' justifyContent='center' width='100%'>
        <Box
          bgcolor='#652d92'
          display='flex'
          justifyContent='center'
          alignItems='center'
          padding='8px'
        >
          <Typography variant='h1' color='white' margin='0'>
            Prescription Drug Copayments
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          {/* Left Column */}
          <Box
            width='30%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Box
              height='96px'
              bgcolor='#f1e7fb'
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderRight='none'
            >
              <Typography variant='h4' margin='0'>
                Coverage
              </Typography>
            </Box>
            <Box
              height='653px'
              display='flex'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderRight='none'
            >
              <Typography variant='body1'>Retail & Mail - PPO Plan</Typography>
            </Box>
          </Box>
          {/* Middle Column */}
          <Box
            width='40%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Box
              height='96px'
              bgcolor='#f1e7fb'
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
            >
              <Typography variant='h4' margin='0'>
                Member Responsibility
              </Typography>
            </Box>
            <Box display='flex' width='100%'>
              {/* Middle Left Column */}
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='20%'
                border='1px solid #000'
                borderBottom='none'
              >
                <Box
                  height='193px'
                  display='flex'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1'>Tier 1</Typography>
                </Box>
                <Box
                  height='153px'
                  display='flex'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1'>Tier 2</Typography>
                </Box>
                <Box
                  height='153px'
                  display='flex'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1'>Tier 3</Typography>
                </Box>
                <Box
                  height='153px'
                  display='flex'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1'>Tier 4</Typography>
                </Box>
              </Box>
              {/* Middle Right Column */}
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='80%'
                border='1px solid #000'
                borderLeft='none'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1' mb={2}>
                    Cost Plus Drugs $0 copay
                  </Typography>
                  <Typography variant='body1'>All other Retail & Mail:</Typography>
                  <Typography variant='body1'>$5 copay 1 - 31 days</Typography>
                  <Typography variant='body1' mb={2}>
                    $10 copay 32 - 90 days
                  </Typography>
                  <Typography variant='body1'>
                    Under 18, Tier 1 copay to be $0 regardless of CPD, Retail, or Mail
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1' mb={2}>
                    CPD $0 copay
                  </Typography>
                  <Typography variant='body1'>All other Retail & Mail:</Typography>
                  <Typography variant='body1'>$35 copay 1 - 31 days</Typography>
                  <Typography variant='body1'>$70 copay 32 - 60 days</Typography>
                  <Typography variant='body1'>$87.50 copay 61 - 90 days</Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  padding='8px'
                  borderBottom='1px solid #000'
                >
                  <Typography variant='body1' mb={2}>
                    CPD $0 copay
                  </Typography>
                  <Typography variant='body1'>All other Retail & Mail:</Typography>
                  <Typography variant='body1'>$50 copay 1 - 31 days</Typography>
                  <Typography variant='body1'>$100 copay 32 - 60 days</Typography>
                  <Typography variant='body1'>$125 copay 61 - 90 days</Typography>
                </Box>
                <Box display='flex' flexDirection='column' width='100%' padding='8px'>
                  <Typography variant='body1'>$65 copay 1 - 31 days</Typography>
                  <Typography variant='body1'>$130 copay 32 - 60 days</Typography>
                  <Typography variant='body1' mb={2}>
                    $150 copay 61 - 90 days
                  </Typography>
                  <Typography variant='body1'>
                    *Drugs designated as Specialty are limited to 30 days supply
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Right Column */}
          <Box
            width='30%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Box
              height='96px'
              bgcolor='#f1e7fb'
              display='flex'
              alignItems='center'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderBottom='none'
              borderLeft='none'
            >
              <Typography variant='h4' align='center' margin='0'>
                Precertification Requirements and Other Coverage Notes
              </Typography>
            </Box>
            <Box
              height='653px'
              display='flex'
              width='100%'
              padding='8px'
              border='1px solid #000'
              borderLeft='none'
            >
              <Typography variant='body1'>
                The prescription drug benefit provides coverage for some drugs only if they are
                prescribed for certain uses. For this reason, some medications must recieve prior
                authorization before you may fill the prescription.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PlanDesign
