import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { TableCell, TableRow, Collapse } from '@mui/material'
import { QRCodeCanvas } from 'qrcode.react'
import Icon from '../stories/Icons'
import Button from '../stories/Button'
import Link from '../stories/Link'
import VoucherDetailsEntry from '../containers/voucherDetailsEntry'
import Typography from '../stories/Typography'
import { formatDateForUI } from '../libs/formatters'
import { checkIfDate } from '../libs/validators'
import AddToCalender from './AddToCalender'

const InfoRow = ({ label = '', body, icon }) => (
  <Box display='flex' alignItems='center' my={2}>
    {icon && <Icon name={icon} fontSize='small' color='primary' />}
    <Box fontWeight={500} ml={2}>
      {label}
    </Box>
    <Box ml={1} mr={2}>
      {body}
    </Box>
  </Box>
)

const LinkButton = ({ link, text }) => (
  <Link
    to={link}
    target='_blank'
    style={{
      padding: '5px 10px',
      margin: '0px',
      borderRadius: '5px',
      backgroundColor: '#662D91',
      color: '#fff',
      boxShadow: '1px 1px 5px 0px #662D91',
    }}
  >
    {text}
  </Link>
)

const VouchersIndividual = ({ open = false, voucher = {}, headers }) => {
  const [data, setData] = useState(false)

  useEffect(() => {
    const formattedInputs = {
      gender: voucher.customer?.gender,
      phone: voucher.customer?.phone,
      dob: formatDateForUI(voucher.customer.dob || voucher.customer.birthday),
      order: voucher.orderId,
      aptDate: voucher.appointmentDate,
      price: voucher.service?.price,
      instruct: voucher.organization?.instructions,
      status: voucher.status,
      code: voucher.code,
      uid: voucher.uid,
      cancelledAt: formatDateForUI(voucher.cancelledAt, true),
      redeemedAt: formatDateForUI(voucher.redeemedAt, true),
      teleLink: voucher.telehealthLink || voucher.organization.telehealthLink,
      booklink: voucher.bookingLink,
      serviceName: voucher.service?.name,
    }
    setData(formattedInputs)
  }, [voucher])

  return (
    <TableRow style={{ backgroundColor: '#faf5ff', border: 0, padding: 0 }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
      <TableCell style={{ padding: '0px' }} colSpan={headers.length - 1}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          {data.gender && <InfoRow label='Gender:' body={data.gender} icon='wc' />}
          {data.dob && <InfoRow label='Dob:' body={data.dob} icon='cake' />}
          {data.order && <InfoRow label='Order:' body={data.order} icon='price' />}
          {data.phone && <InfoRow label='Phone:' body={data.phone} icon='phone' />}
          {data.price && <InfoRow label='Price:' body={`$${data.price}`} icon='card' />}
          {data.instruct && (
            <InfoRow label='Scheduling Info:' body={data.instruct} icon='calendar' />
          )}

          {data.status === 'purchased' && (
            <>
              <InfoRow
                body={
                  <QRCodeCanvas value={`${window.location.origin}/dashboard?claim=${data.code}`} />
                }
                icon='qrCode'
              />
              <InfoRow
                body={
                  <LinkButton link={`/videochat?id=${data.uid}`} text='Join Mishe Video Call' />
                }
                icon='videoCall'
              />
            </>
          )}

          {data.cancelledAt && (
            <InfoRow label='Cancelled at:' body={data.cancelledAt} icon='star' />
          )}
          {data.redeemedAt && <InfoRow label='Redeemed at:' body={data.redeemedAt} icon='star' />}
          {data.teleLink && (
            <InfoRow
              body={<LinkButton link={data.teleLink} text='Join External Call' />}
              icon='videoCall'
            />
          )}
          {data.bookLink && (
            <InfoRow body={<LinkButton link={data.bookLink} text='Book here' />} icon='videoCall' />
          )}

          <Box display='flex' alignItems='center' my={2}>
            <Icon name='edit' fontSize='small' color='primary' />
            <Box ml={2}>
              <VoucherDetailsEntry voucher={voucher}>
                <Button size='small' variant='outlined'>
                  <Typography variant='body1' family='poppins'>
                    Details
                  </Typography>
                </Button>
              </VoucherDetailsEntry>
            </Box>
            {checkIfDate(data.aptDate) && (
              <AddToCalender
                calenderEvent={{
                  title: data.serviceName,
                  description: `Voucher Link:  ${window.location.origin}/dashboard?vc=${data.code}`,
                  start: data.aptDate?.toDate()?.toISOString(),
                  appointmentDate: data?.aptDate,
                  duration: [1, 'hour'],
                }}
              />
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default VouchersIndividual
