import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  AccountCircleOutlined,
  Add,
  AddAPhoto,
  Announcement,
  ArrowRightRounded,
  AttachFile,
  AssignmentOutlined,
  BusinessOutlined,
  Cake,
  CallEndOutlined,
  ChatOutlined,
  CheckOutlined,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  CreditCard,
  DeleteOutline,
  DesktopAccessDisabledOutlined,
  DesktopWindowsOutlined,
  EditCalendarOutlined,
  EditNote,
  Event,
  ExitToApp,
  Facebook,
  FiberManualRecord,
  FiberManualRecordOutlined,
  FilterList,
  GetApp,
  HelpOutline,
  InfoOutlined,
  Instagram,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowUp,
  LinkedIn,
  LocalHospital,
  LocalOffer,
  LocalPhone,
  MarkunreadMailboxOutlined,
  MicNoneOutlined,
  MicOffOutlined,
  NavigateBefore,
  NoteAddOutlined,
  PageviewOutlined,
  QrCode,
  Room,
  RoomOutlined,
  RoomTwoTone,
  Search,
  Send,
  ShoppingCartOutlined,
  Star,
  StarOutline,
  Sync,
  Twitter,
  VideocamOffOutlined,
  VideocamOutlined,
  VideoCall,
  VisibilityOffOutlined,
  VisibilityOutlined,
  Wc,
  YouTube,
} from '@mui/icons-material'

const IconDecorator = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
`

const Icon = ({ name = '', fontSize = 'medium', color = 'inherit', ...props }) => {
  return (
    <IconDecorator>
      {
        {
          account: (
            <AccountCircleOutlined fontSize={fontSize} title='account' color={color} {...props} />
          ),
          add: <Add fontSize={fontSize} title='add' color={color} {...props} />,
          addPhoto: <AddAPhoto fontSize={fontSize} title='add photo' color={color} />,
          announcement: (
            <Announcement fontSize={fontSize} title='announcement' color={color} {...props} />
          ),
          attachFile: <AttachFile fontSize={fontSize} title='attach file' color={color} />,
          assignmentOutlined: (
            <AssignmentOutlined fontSize={fontSize} title='assignment' color={color} {...props} />
          ),
          back: <NavigateBefore fontSize={fontSize} title='back' color={color} {...props} />,
          bullet: (
            <ArrowRightRounded fontSize={fontSize} title='bulletArrow' color={color} {...props} />
          ),
          cake: <Cake fontSize={fontSize} title='cake' color={color} />,
          calendar: <Event fontSize={fontSize} title='calendar' color={color} {...props} />,
          callEndOutlined: (
            <CallEndOutlined
              fontSize={fontSize}
              title='end call'
              color={color}
              style={{ color }}
              {...props}
            />
          ),
          card: <CreditCard fontSize={fontSize} title='credit card' color={color} {...props} />,
          cart: <ShoppingCartOutlined fontSize={fontSize} title='cart' color={color} {...props} />,
          chatOutlined: <ChatOutlined fontSize={fontSize} title='chat' color={color} {...props} />,
          check: <CheckOutlined fontSize={fontSize} title='check' color={color} {...props} />,
          chevronLeft: (
            <ChevronLeft fontSize={fontSize} title='chevron left' color={color} {...props} />
          ),
          chevronRight: (
            <ChevronRight fontSize={fontSize} title='chevron right' color={color} {...props} />
          ),
          clear: <Clear fontSize={fontSize} title='clear' color={color} {...props} />,
          close: <Close fontSize={fontSize} title='close' color={color} {...props} />,
          closeArrow: (
            <KeyboardArrowUp fontSize={fontSize} title='close' color={color} {...props} />
          ),
          cross: <LocalHospital fontSize={fontSize} title='health care' color={color} {...props} />,
          delete: <DeleteOutline fontSize={fontSize} title='delete' color={color} {...props} />,
          desktopAccessDisabledOutlined: (
            <DesktopAccessDisabledOutlined
              fontSize={fontSize}
              title='desktop access disabled'
              color={color}
              {...props}
            />
          ),
          desktopWindowsOutlined: (
            <DesktopWindowsOutlined
              fontSize={fontSize}
              title='desktop windows'
              color={color}
              {...props}
            />
          ),
          delivery: (
            <MarkunreadMailboxOutlined
              fontSize={fontSize}
              title='delivery'
              color={color}
              {...props}
            />
          ),
          edit: <EditNote fontSize={fontSize} title='edit' color={color} {...props} />,
          editCalendarOutlined: (
            <EditCalendarOutlined
              fontSize={fontSize}
              title='edit calendar'
              color={color}
              {...props}
            />
          ),
          exitToApp: <ExitToApp fontSize={fontSize} title='exit to app' color={color} {...props} />,
          facebook: (
            <Facebook
              fontSize={fontSize}
              title='facebook'
              color={color}
              style={{ color }}
              {...props}
            />
          ),
          fiberManualRecord: (
            <FiberManualRecord fontSize={fontSize} title='record' color={color} {...props} />
          ),
          fiberManualRecordOutlined: (
            <FiberManualRecordOutlined
              fontSize={fontSize}
              title='record'
              color={color}
              {...props}
            />
          ),
          filter: <FilterList fontSize={fontSize} title='filter' color={color} {...props} />,
          getApp: <GetApp fontSize={fontSize} title='download' color={color} {...props} />,
          info: <InfoOutlined fontSize={fontSize} title='info' color={color} {...props} />,
          instagram: (
            <Instagram
              fontSize={fontSize}
              title='instagram'
              color={color}
              style={{ color }}
              {...props}
            />
          ),
          leftArrow: (
            <KeyboardArrowLeft fontSize={fontSize} title='left arrow' color={color} {...props} />
          ),
          linkedin: <LinkedIn fontSize={fontSize} title='linkedin' color={color} {...props} />,
          location: <Room fontSize={fontSize} title='location' color={color} {...props} />,
          locationOutlined: (
            <RoomOutlined fontSize={fontSize} title='location' color={color} {...props} />
          ),
          locationTwoTone: (
            <RoomTwoTone fontSize={fontSize} title='location' color={color} {...props} />
          ),
          micNoneOutlined: (
            <MicNoneOutlined fontSize={fontSize} title='mic' color={color} {...props} />
          ),
          micOffOutlined: (
            <MicOffOutlined fontSize={fontSize} title='mic off' color={color} {...props} />
          ),
          noteAddOutlined: (
            <NoteAddOutlined fontSize={fontSize} title='note add' color={color} {...props} />
          ),
          openArrow: (
            <KeyboardArrowDown fontSize={fontSize} title='open' color={color} {...props} />
          ),
          pageViewOutlined: (
            <PageviewOutlined fontSize={fontSize} title='page view' color={color} {...props} />
          ),
          phone: <LocalPhone fontSize={fontSize} title='phone' color={color} {...props} />,
          pickUp: <BusinessOutlined fontSize={fontSize} title='pick up' color={color} {...props} />,
          price: <LocalOffer fontSize={fontSize} title='price tag' color={color} {...props} />,
          qrCode: <QrCode fontSize={fontSize} title='qr-code' color={color} {...props} />,
          question: <HelpOutline fontSize={fontSize} title='question' color={color} {...props} />,
          search: <Search fontSize={fontSize} title='search' color={color} {...props} />,
          send: <Send fontSize={fontSize} title='send' color={color} {...props} />,
          star: <Star fontSize={fontSize} title='star' color={color} {...props} />,
          starOutline: <StarOutline fontSize={fontSize} title='star' color={color} {...props} />,
          sync: <Sync fontSize={fontSize} title='sync' color={color} {...props} />,
          tiktok: (
            <svg
              fill={color === 'primary' ? '#652D92' : 'white'}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 50 50'
              width='24'
              height='24'
            >
              <path d='M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z' />
            </svg>
          ),
          top: <KeyboardArrowUp fontSize={fontSize} title='back to top' color={color} {...props} />,
          twitter: (
            <Twitter
              fontSize={fontSize}
              title='twitter'
              color={color}
              style={{ color }}
              {...props}
            />
          ),
          videoCall: <VideoCall fontSize={fontSize} title='video call' color={color} {...props} />,
          videocamOutlined: (
            <VideocamOutlined fontSize={fontSize} title='video call' color={color} {...props} />
          ),
          videocamOffOutlined: (
            <VideocamOffOutlined fontSize={fontSize} title='video call' color={color} {...props} />
          ),
          visibility: (
            <VisibilityOutlined fontSize={fontSize} title='visibility' color={color} {...props} />
          ),
          visibilityOff: (
            <VisibilityOffOutlined
              fontSize={fontSize}
              title='visibility off'
              color={color}
              {...props}
            />
          ),
          wc: <Wc fontSize={fontSize} title='wc' color={color} {...props} />,
          youtube: (
            <YouTube
              fontSize={fontSize}
              title='youtube'
              color={color}
              style={{ color }}
              {...props}
            />
          ),
        }[name]
      }
    </IconDecorator>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
}

export default Icon
