import { useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Typography from '@app/stories/Typography'

const ClaimsRegister = () => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'amountPulled', headerName: 'Amount Pulled', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'transferId', headerName: 'Transfer ID', width: 150 },
  ]

  const rows = [
    {
      id: 1,
      amountPulled: 500.0,
      status: 'paid',
      date: '2024-10-01',
      transferId: 'TRF001234',
    },
    {
      id: 2,
      amountPulled: 750.5,
      status: 'in transit',
      date: '2024-10-05',
      transferId: 'TRF001235',
    },
    {
      id: 3,
      amountPulled: 1200.0,
      status: 'pulled',
      date: '2024-10-10',
      transferId: 'TRF001236',
    },
    {
      id: 4,
      amountPulled: 300.25,
      status: 'paid',
      date: '2024-10-15',
      transferId: 'TRF001237',
    },
    {
      id: 5,
      amountPulled: 980.75,
      status: 'in transit',
      date: '2024-10-20',
      transferId: 'TRF001238',
    },
    {
      id: 6,
      amountPulled: 1500.0,
      status: 'pulled',
      date: '2024-10-25',
      transferId: 'TRF001239',
    },
    {
      id: 7,
      amountPulled: 450.0,
      status: 'paid',
      date: '2024-10-30',
      transferId: 'TRF001240',
    },
    {
      id: 8,
      amountPulled: 2200.5,
      status: 'in transit',
      date: '2024-11-01',
      transferId: 'TRF001241',
    },
    {
      id: 9,
      amountPulled: 800.25,
      status: 'pulled',
      date: '2024-11-05',
      transferId: 'TRF001242',
    },
    {
      id: 10,
      amountPulled: 1100.0,
      status: 'paid',
      date: '2024-11-10',
      transferId: 'TRF001243',
    },
  ]

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          minHeight: '200px',
        }}
      />
    </Box>
  )
}

export default ClaimsRegister
