// DateTimePickerCell.js
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import styled from '@mui/system/styled'
import { formatDateForUI } from '../libs/formatters'
import { collection } from '../firebase/firestore'
import { Timestamp } from 'firebase/firestore'

const DateTimePickerStyled = styled(DateTimePicker)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderWidth: 0 },
    '&:hover fieldset': { borderWidth: 0 },
    '&.Mui-focused fieldset': { borderWidth: '1px' },
  },
}))

const DateTimePickerCell = ({ appointmentDate, voucherId }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const onAcceptTriggerAppDate = async date => {
    if (voucherId) {
      try {
        await collection('vouchers').doc(voucherId).set({ appointmentDate: date }, { merge: true })
        setSnackbarOpen(true)
      } catch (error) {
        console.error('Failed to update appointment date:', error)
      }
    }
  }

  const handleCloseSnackbar = () => setSnackbarOpen(false)

  return (
    <>
      <DateTimePickerStyled
        slotProps={{ actionBar: { actions: ['cancel', 'accept'] } }}
        onAccept={onAcceptTriggerAppDate}
        defaultValue={appointmentDate ? new Date(formatDateForUI(appointmentDate, true)) : null}
        label={appointmentDate ? '' : 'Add Date'}
      />
      <Snackbar
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message='Appointment Date Updated'
      />
    </>
  )
}

DateTimePickerCell.propTypes = {
  appointmentDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(Timestamp),
  ]),
  voucherId: PropTypes.string.isRequired,
}

export default DateTimePickerCell
