import { useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const MemberCensus = () => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', width: 150 },
    { field: 'lastName', headerName: 'Last name', width: 150 },
    { field: 'dateOfBirth', headerName: 'Date of birth', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 110 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone number', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'membershipStatus', headerName: 'Membership status', width: 150 },
    { field: 'lastCheckup', headerName: 'Last checkup', width: 150 },
    { field: 'isDependent', headerName: 'Is dependent', width: 150 },
    { field: 'dependentType', headerName: 'Dependent type', width: 150 },
  ]

  const rows = [
    {
      id: 1,
      firstName: 'John',
      lastName: 'Doe',
      dateOfBirth: '1985-03-15',
      gender: 'Male',
      address: '123 Main St, Anytown, USA',
      phoneNumber: '(555) 123-4567',
      email: 'john.doe@email.com',
      insuranceProvider: 'HealthCare Plus',
      membershipStatus: 'Active',
      lastCheckup: '2024-02-10',
      isDependent: false,
      dependentType: null,
    },
    {
      id: 2,
      firstName: 'Jane',
      lastName: 'Smith',
      dateOfBirth: '1990-07-22',
      gender: 'Female',
      address: '456 Oak Ave, Somewhere, USA',
      phoneNumber: '(555) 987-6543',
      email: 'jane.smith@email.com',
      insuranceProvider: 'MediCover',
      membershipStatus: 'Active',
      lastCheckup: '2023-11-30',
      isDependent: true,
      dependentType: 'Spouse',
    },
    {
      id: 3,
      firstName: 'Michael',
      lastName: 'Johnson',
      dateOfBirth: '1978-12-05',
      gender: 'Male',
      address: '789 Pine Rd, Elsewhere, USA',
      phoneNumber: '(555) 246-8135',
      email: 'michael.johnson@email.com',
      insuranceProvider: 'National Health',
      membershipStatus: 'Inactive',
      lastCheckup: '2022-09-15',
      isDependent: false,
      dependentType: null,
    },
    {
      id: 4,
      firstName: 'Emily',
      lastName: 'Brown',
      dateOfBirth: '2010-04-30',
      gender: 'Female',
      address: '101 Cedar Ln, Nowhere, USA',
      phoneNumber: '(555) 369-2580',
      email: 'emily.brown@email.com',
      insuranceProvider: 'HealthCare Plus',
      membershipStatus: 'Active',
      lastCheckup: '2024-01-20',
      isDependent: true,
      dependentType: 'Child',
    },
    {
      id: 5,
      firstName: 'David',
      lastName: 'Wilson',
      dateOfBirth: '1982-09-18',
      gender: 'Male',
      address: '202 Elm St, Anyplace, USA',
      phoneNumber: '(555) 147-2589',
      email: 'david.wilson@email.com',
      insuranceProvider: 'MediCover',
      membershipStatus: 'Active',
      lastCheckup: '2023-12-05',
      isDependent: false,
      dependentType: null,
    },
    {
      id: 6,
      firstName: 'Sarah',
      lastName: 'Taylor',
      dateOfBirth: '1988-11-03',
      gender: 'Female',
      address: '303 Maple Dr, Someplace, USA',
      phoneNumber: '(555) 753-9514',
      email: 'sarah.taylor@email.com',
      insuranceProvider: 'National Health',
      membershipStatus: 'Active',
      lastCheckup: '2024-03-01',
      isDependent: true,
      dependentType: 'Spouse',
    },
    {
      id: 7,
      firstName: 'Robert',
      lastName: 'Anderson',
      dateOfBirth: '1975-06-12',
      gender: 'Male',
      address: '404 Birch Blvd, Othertown, USA',
      phoneNumber: '(555) 951-7532',
      email: 'robert.anderson@email.com',
      insuranceProvider: 'HealthCare Plus',
      membershipStatus: 'Inactive',
      lastCheckup: '2023-07-10',
      isDependent: false,
      dependentType: null,
    },
    {
      id: 8,
      firstName: 'Jennifer',
      lastName: 'Martinez',
      dateOfBirth: '2015-01-25',
      gender: 'Female',
      address: '505 Walnut Ave, Elsewhere, USA',
      phoneNumber: '(555) 357-1590',
      email: 'jennifer.martinez@email.com',
      insuranceProvider: 'MediCover',
      membershipStatus: 'Active',
      lastCheckup: '2024-02-28',
      isDependent: true,
      dependentType: 'Child',
    },
    {
      id: 9,
      firstName: 'William',
      lastName: 'Thompson',
      dateOfBirth: '1980-08-09',
      gender: 'Male',
      address: '606 Spruce Ct, Somewhere, USA',
      phoneNumber: '(555) 852-9630',
      email: 'william.thompson@email.com',
      insuranceProvider: 'National Health',
      membershipStatus: 'Active',
      lastCheckup: '2023-10-15',
      isDependent: false,
      dependentType: null,
    },
    {
      id: 10,
      firstName: 'Lisa',
      lastName: 'Garcia',
      dateOfBirth: '2008-05-17',
      gender: 'Female',
      address: '707 Ash Rd, Anytown, USA',
      phoneNumber: '(555) 741-8520',
      email: 'lisa.garcia@email.com',
      insuranceProvider: 'HealthCare Plus',
      membershipStatus: 'Active',
      lastCheckup: '2024-01-05',
      isDependent: true,
      dependentType: 'Child',
    },
  ]

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          minHeight: '200px',
        }}
      />
    </Box>
  )
}

export default MemberCensus
