import { useEffect, useRef, useState } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import PatientVouchers from '../containers/PatientVouchers'
import ProviderVouchers from './ProviderVouchers'
import ProfileForm from '../components/ProfileForm'
import OrdersDashboardContent from './OrdersDashboardContent'
import PlansDashboardContent from './PlansDashboardContent'
import PlansPatientDashboardContent from './PlansPatientDashboardContent'
import ReportsContent from './ReportsContent'
import ProviderOrders from './ProviderOrders'
import ChangePasswordForm from '../containers/ChangePasswordForm'
import VoucherDetailsContainer from '../containers/VoucherDetailsContainer'
import { useCurrentProfile } from '../context/CurrentUser'
import { useVoucherDetails } from '../context/VoucherDetails'
import useSteps from '../hooks/useSteps'
import Tabs, { Tab } from '../stories/Tabs'
import Box from '@mui/material/Box'
import Heading from '../stories/Heading'
import Link from '../stories/Link'
import AddNoteModal from '../containers/AddNoteModalContainer'
import AddOrderModal from '../containers/AddOrderModalContainer'
import parseQueryString from '../libs/parseQueryStrings'
import { useVouchers } from '../context/Vouchers'
import IdInputForm from './IdInputForm'
// import ShippingAddressContainer from '../containers/ShippingAddressContainer'
import PreferencesForm from './PreferencesForm'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PlanDesign from './PlanAdminDashboard/PlanDesign'
import EFTEnrollment from './PlanAdminDashboard/EFTEnrollment'
import ProviderDirectory from './PlanAdminDashboard/ProviderDirectory'
import MemberCensus from './PlanAdminDashboard/MemberCensus'
import ClaimsActivity from './PlanAdminDashboard/ClaimsActivity'
import ClaimsRegister from './PlanAdminDashboard/ClaimsRegister'

const DashboardTabs = () => {
  const { search } = useLocation()
  const { step, changeStep } = useSteps()

  const { vouchers } = useVouchers()

  const { voucher, handleClick: voucherClick } = useVoucherDetails()

  const { uid, organizationId, isPatient, isPlanAdmin, ...profile } = useCurrentProfile()

  const { vc } = parseQueryString(search)

  const voucherCodeQuery = useRef(vc)

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // Detect if it's a mobile view

  useEffect(() => {
    if (voucherCodeQuery.current && vouchers) {
      if (voucherCodeQuery.current) {
        const voucherRef = vouchers.find(v => v.code === voucherCodeQuery.current)
        if (voucherRef) {
          voucherClick(voucherRef)
          voucherCodeQuery.current = null
        }
      }
    }
  }, [voucherCodeQuery.current, vouchers]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Paper>
        {isPlanAdmin ? (
          <Tabs
            value={step}
            onChange={changeStep}
            scrollButtons
            variant={isMobile ? 'standard' : 'scrollable'}
            allowScrollButtonsMobile
            orientation={isMobile ? 'vertical' : 'horizontal'} // Change to vertical on mobile
          >
            <Tab label='Claims Register' size='growXS' />
            <Tab label='Claims Activity' size='growXS' />
            <Tab label='Member Census' size='growXS' />
            <Tab label='Provider Directory' size='growXS' />
            <Tab label='EFT Enrollment' size='growXS' />
            <Tab label='Plan Design/ Details' size='growXS' />
          </Tabs>
        ) : (
          <Tabs
            value={step}
            onChange={changeStep}
            scrollButtons
            variant={isMobile ? 'standard' : 'scrollable'}
            allowScrollButtonsMobile
            orientation={isMobile ? 'vertical' : 'horizontal'} // Change to vertical on mobile
          >
            <Tab label='Claims' size='growXS' />
            <Tab label='My Info' size='growXS' />
            <Tab label='Orders' size='growXS' />
            <Tab label='Reports' size='growXS' />
            {/* <Tab label='Chats' size='growXS' />  */}
            <Tab label='Plans' size='growXS' />
          </Tabs>
        )}
      </Paper>
      {isPlanAdmin ? (
        <>
          {step === 0 && (
            <Box paddingY='16px'>
              <ClaimsRegister />
            </Box>
          )}
          {step === 1 && (
            <Box paddingY='16px'>
              <ClaimsActivity />
            </Box>
          )}
          {step === 2 && (
            <Box paddingY='16px'>
              <MemberCensus />
            </Box>
          )}
          {step === 3 && (
            <Box paddingY='16px'>
              <ProviderDirectory />
            </Box>
          )}
          {step === 4 && (
            <Box paddingY='16px'>
              <EFTEnrollment />
            </Box>
          )}
          {step === 5 && (
            <Box paddingY='16px'>
              <PlanDesign />
            </Box>
          )}
        </>
      ) : (
        <>
          {step === 0 && (
            <Box>
              {isPatient && !voucher && <PatientVouchers uid={uid} />}
              {!isPatient && !voucher && <ProviderVouchers uid={organizationId} />}
              {voucher && (
                <>
                  <VoucherDetailsContainer />
                  <AddNoteModal />
                  <AddOrderModal />
                </>
              )}
            </Box>
          )}
          {step === 1 && (
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <ProfileForm uid={uid} values={profile} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <PreferencesForm uid={uid} values={profile} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <IdInputForm />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <ChangePasswordForm />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box>
                  <Heading variant='h3'>Certificate of Coverage</Heading>
                  <Typography>Place holder</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Heading variant='h3'>Request personal information</Heading>

                <Typography>
                  To request or delete your personal data, please contact us at{' '}
                  <Link type='external' display='inline' to='mailto:help@mishe.co'>
                    help@mishe.co
                  </Link>{' '}
                  or{' '}
                  <Link type='external' display='inline' to='tel:+1 (601) 647–4326'>
                    1-855-MISHECO
                  </Link>{' '}
                  (
                  <Link type='external' display='inline' size='small' to='tel:+1 (601) 647–4326'>
                    1-855-647-4326
                  </Link>
                  ).
                </Typography>

                <Typography>
                  You can also check our{' '}
                  <Link
                    target='_blank'
                    display='inline'
                    size='small'
                    to={isPatient ? '/hipaa' : '/baa'}
                  >
                    {isPatient ? 'HIPAA' : 'BAA'}
                  </Link>{' '}
                  and{' '}
                  <Link
                    target='_blank'
                    display='inline'
                    size='small'
                    to={isPatient ? '/eula-consumer' : '/eula-provider'}
                  >
                    EULA
                  </Link>{' '}
                  agreements.
                </Typography>
              </Grid>
            </Grid>
          )}
          {step === 2 && (
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {isPatient && <OrdersDashboardContent />}
                {!isPatient && <ProviderOrders />}
              </Grid>
            </Grid>
          )}
          {step === 3 && (
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ReportsContent />
              </Grid>
            </Grid>
          )}
          {step === 4 && (
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {isPatient ? <PlansPatientDashboardContent /> : <PlansDashboardContent />}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default DashboardTabs
